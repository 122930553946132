@import '../../variables.scss';

.colorPink{
    color: $color-logo-pink;
    &:hover {
        border: 1px solid $color-logo-pink;
        background: $color-logo-pink-dark;
    }
}
.colorBlue{
    color: $color-logo-blue;
    &:hover {
        border: 1px solid $color-logo-blue;
        background: $color-logo-blue-dark;
    }
}
.colorGreen{
    color: $color-logo-green;
    &:hover {
        border: 1px solid $color-logo-green;
        background: $color-logo-green-dark;
    }
}

.inlineLink {
    text-decoration: none;
    padding: 0.1rem 0.3rem;
    border-radius: 3px;
    border: 1px solid transparent;
    &:hover {
        color: white;
    }
}