@import '../../variables.scss';

.container {
  margin-top: 2rem;
  box-sizing: border-box;
  background: black;
  width: 100%;
  padding: 2rem;
  height: auto;
}

.program {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  h2 {
    margin: 0;
  }

  @media only screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
  }
}

.speaker {
  color: $color-logo-orange;
  font-size: 1.3rem;
}

.bio {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.bioTittle {
  font-size: 1.1rem;
  font-weight: 600;
}

.partnerAir {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.partner {
  width: 10rem;
  @media only screen and (max-width: $screen-sm) {
    width: 8rem;
  }
}

.time {
  min-width: 12rem;
  text-align: start;
}

.description {
  width: 100%;
  text-align: start;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.talkMainInfo{
  display: flex;
  flex-direction: column;
}

.talkContainer{
  display: flex;
  align-content: center;
}

.video{
  margin-left: 0.5rem;
}

.talk {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

}

.clickable {
  cursor: pointer;
}

.talk:last-of-type {
  flex-direction: column;
  margin-bottom: 0;
}

.active {
  color: white;
  background: $color-logo-red-dark;
}

.abstractTitle {
  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 1rem;
}
.abstract {
  margin-left: 1rem;
  font-size: 1.3rem;
}
