
.iframe {
    border: none;
}

.back{
    margin: 0 auto;
    padding-bottom: 10px;
    width: fit-content;
}

